import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import Icon from "./Icon";
import { Wrapper } from "./CourseCard";
import { gradientColors } from "../utils/formatters";
// import { track } from "../store/kikudooTracking";

const PlacementWrapper = styled(Wrapper)`
  padding: 0.25rem;
  border-radius: ${props => props.theme.borderRadius};
  background-image: linear-gradient(
    151deg,
    ${props =>
      props.color && props.color !== "#000000"
        ? gradientColors(props.color)
        : "rgb(108, 149, 225), #6f42c1"}
  );
`;

const Content = styled.div`
  padding: 1rem 2rem 1rem 1rem;
  background-image: linear-gradient(0deg, rgba(34, 9, 91, 0.5) 33%, rgba(42, 16, 80, 0.2) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0 0 ${props => props.theme.borderRadius} ${props => props.theme.borderRadius};
  // backdrop-filter: blur(3px);
`;

const Tagline = styled.span`
  font-size: 1.5em;
  font-family: "Quicksand";
  font-weight: 400;
  letter-spacing: 0.05em;
  color: ${props => props.theme.white};
  text-align: center;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
`;

const Poster = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
  width: 100%;
  min-height: 400px;
  position: relative;
  border-radius: ${props => props.theme.borderRadius};
`;

const CTA = styled.aside`
  padding: 0.5rem 0.35rem 0.25rem;
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.white};
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
`;

const ActionName = styled.strong`
  font-weight: bold;
  font-family: "Quicksand";
  letter-spacing: 0.05em;
  color: inherit;
  text-overflow: ellipses;
  overflow: hidden;
  flex: 1;
`;

const PlacementCard = ({ placement, listView }) => {
  const trackClick = () => {
    // track("a", placement.id);
  };

  return (
    <PlacementWrapper listView={listView} color={placement.color}>
      <a
        href={placement.url}
        target="_blank"
        referrerPolicy="no-referrer-when-downgrade"
        allowReferrer={true}
        rel="noopener noreferrer"
        onClick={trackClick}
      >
        <Poster style={{ backgroundImage: `url('${placement.image}')` }}>
          {placement.content && (
            <Content>
              <Tagline>{placement.content}</Tagline>
            </Content>
          )}
        </Poster>
        <CTA>
          <ActionName>{placement.actionName}</ActionName>
          <Icon name="long-arrow-right" />
        </CTA>
      </a>
    </PlacementWrapper>
  );
};

PlacementCard.propTypes = {
  placement: PropTypes.object.isRequired,
  listView: PropTypes.bool,
};

PlacementCard.defaultProps = {
  listView: false,
};

export default PlacementCard;
