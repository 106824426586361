import { API_URL } from "../utils/Api";
import { LOCATION_CHANGE } from "react-router-redux";
import { matchPath } from "react-router-dom";
import routes from "../routes";

const PIXEL_ID = "kikudoo-tracking-pixel";
const TRACKS_LS = "kikuTrxs";
const HOURS_BY_TRACK = 1;

const hoursDiff = (d1, d2) => Math.abs(d1 - d2) / 36e5;

const pixel = () => {
  let element = document.getElementById(PIXEL_ID);

  if (!element) {
    element = new Image();
    element.id = PIXEL_ID;
    element.style.position = "fixed";
    element.style.height = "1px";
    element.style.width = "1px";
    element.style.overflow = "hidden";
    document.body.appendChild(element);
  }

  return element;
};

// param stand for:
// - c for Course view
// - e for Event view
// - p for Provider view
// - b for Post view
// - a for Placement view
// like: api/trk.gif?a=pB91Bg&t=1734092128125
export const track = (type, id) => {
  pixel().src = `${API_URL}trk.gif?${type}=${id}&t=${Date.now()}`;
  // Only store track if its not a placement
  if (type !== "a") {
    setTrack([type, id].join(""));
  }
};

const setTrack = id => {
  if (!window.localStorage || !id) {
    return;
  }

  const tracks = JSON.parse(localStorage.getItem(TRACKS_LS) || "{}");
  tracks[id] = Date.now();

  localStorage.setItem(TRACKS_LS, JSON.stringify(tracks));
};

const isTracked = (type, id) => {
  if (!window.localStorage || !id) {
    return false;
  }
  const key = [type, id].join("");
  // As NS_ERROR_FILE_NO_DEVICE_SPACE error can appear on Windows, make sure that this check doesn't throw error.
  try {
    const tracks = JSON.parse(localStorage.getItem(TRACKS_LS) || "{}");
    const today = new Date();
    return tracks[key] && hoursDiff(today, new Date(parseInt(tracks[key]))) < HOURS_BY_TRACK;
  } catch (e) {
    return false;
  }
};

// This handles kikudoo internal/custom user tracking
export default ({ getState }) => {
  return next => action => {
    switch (action.type) {
      case LOCATION_CHANGE: {
        const pathname = action.payload.location.pathname;

        if (pathname.match(/courses/)) {
          const pathMatch = matchPath(pathname, routes.course);

          if (pathMatch && pathMatch.isExact && pathMatch.params) {
            const id = pathMatch.params.id;
            if (id && !isTracked("c", id)) {
              track("c", id);
            } else {
              console.log("already tracked");
            }
          }
        } else if (pathname.match(/blog/)) {
          const pathMatch = matchPath(pathname, routes.post);

          if (pathMatch && pathMatch.isExact && pathMatch.params) {
            const id = pathMatch.params.id;
            if (id && !isTracked("b", id)) {
              track("b", id);
            }
          }
        } else {
          if (pathname.match(/search/) || pathname === "me") {
            break;
          }

          const pathMatch = matchPath(pathname, routes.provider);

          if (pathMatch && pathMatch.isExact && pathMatch.params) {
            const id = pathMatch.params.slug;
            if (id && !isTracked("p", id)) {
              track("p", id);
            }
          }
        }

        break;
      }

      // Booking
      // case BookingActionTypes.UPDATE_CURRENT:{
      //   break;
      // }

      default: {
        break;
      }
    }

    return next(action);
  };
};
